import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-google-map",
  templateUrl: "./google-map.component.html",
  styleUrls: ["./google-map.component.scss"]
})
export class GoogleMapComponent implements OnInit {
  public lat: any = 51.678418;
  public lng: any = 7.809007;

  constructor(
    private dialogRef: MatDialogRef<GoogleMapComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    if (this.data) {
      console.log(this.data);
      this.lat = this.data.Lat;
      this.lng = this.data.Lng;
    }
  }

  closeModal() {
    this.dialogRef.close();
  }
}
