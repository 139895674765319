import { Component, Optional, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-message-view-order',
  templateUrl: './message-view-order.component.html',
  styleUrls: ['./message-view-order.component.scss']
})
export class MessageViewOrderComponent implements OnInit {
  public items;
  public message;
  public requestType;
  public InternalService;
  public serviceType;
  public cartItems=[];
  constructor(public dialogRef: MatDialogRef<MessageViewOrderComponent>, @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    console.log("data passed from messaging")
    console.log(data);
    this.items = data.Items;
    this.message = data.Message.replace(/_/g, ' ');
    this.requestType = data.RequestType;
    this.serviceType = data.ServiceType;
    this.InternalService = data.InternalService;
   }

  ngOnInit(): void {
    let Items = []
    if(this.items.length > 0){
      for(let i = 0; i < this.items.length; i++){
        if(this.items[i].hasOwnProperty('Quantity')){
          if(this.items[i].Quantity != 0){
            Items.push(this.items[i]);
          }
          // console.log("this is has own property Quantity ",this.items[i].hasOwnProperty('Quantity') );
          // this.items.splice(i,1);
        }
      }
    }
    if(Items){
      for(let item of Items){
        if(item){
          
              this.cartItems.push({
                Name: this.requestType == 'service'|| this.InternalService ==true ?item.ServiceItemsNameLang[0].Value:item.ResturantItemsNameLang[0].Value,
                Quantity: item.Quantity
              });
              // console.log("this is items", this.cartItems);
        }
      }
    }
  }

}
