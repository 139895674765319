import { Component, OnInit } from '@angular/core';
import { HotelLoginModel } from '../../models/request/hotel-login.model';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { GlobalService } from '../../services/global.service';
import { ResetAuthCheckService } from '../../services/reset-auth-check.service';
@Component({
  selector: 'app-reset-auth-check',
  templateUrl: './reset-auth-check.component.html',
  styleUrls: ['./reset-auth-check.component.scss']
})
export class ResetAuthCheckComponent implements OnInit {
  public urlParam:string;
  constructor(public globalService:GlobalService,private route:ActivatedRoute,private router:Router,private resetAuthCheckService:ResetAuthCheckService) { }
  
  ngOnInit() {
    if(this.route.snapshot.paramMap.has('secret')){
      this.urlParam = this.route.snapshot.paramMap.get('secret');
      console.log("Param "+this.urlParam);
      this.globalService.showLoader=true; 

      this.resetAuthCheckService.checkSecret({'Secret':this.urlParam}).subscribe(response=>{
          if(response.Status){
            this.router.navigate(['/resetPassword/'+this.urlParam]);
          }else{
            this.globalService.showLoader=false; 
            this.globalService.handleApiError(response);
          }
      },error =>{
        this.globalService.showLoader=false; 
        this.globalService.handleApiError(error);
      })

    }else{
      this.router.navigate(['/']);
    }
  }

}
