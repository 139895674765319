import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  CanActivateChild,
  Router
} from "@angular/router";
import { Observable } from "rxjs";

@Injectable()
export class CanActivateTeamGuard implements CanActivateChild {
  constructor(private router: Router) {}
  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    console.log(route.routeConfig);
    let split = state.url.split("/");
    let x = split.slice(0, split.length - 1).join("/") + "/" + "homeMenu";
    console.log(split[split.length - 1]);
    if (split[split.length - 1] == "manage_branding") {
      const tree: UrlTree = this.router.parseUrl(x);
      return tree;
    } else {
      return true;
    }
  }
}
