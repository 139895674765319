import { Component, Input, OnInit,EventEmitter, Output } from '@angular/core';
import _moment from "moment";
const moment = _moment;

@Component({
  selector: 'app-count-down-timer',
  templateUrl: './count-down-timer.component.html',
  styleUrls: ['./count-down-timer.component.scss']
})
export class CountDownTimerComponent implements OnInit {
  public minutes;
  public seconds;
  public estimatedTime;
  @Input() initialMinutes: number = 0;
  @Input() initialSeconds: number = 0;
  @Input() timerResolve: boolean = true;
  @Output() timeExceed = new EventEmitter();

  
  constructor() { }

  ngOnInit(): void {
    // this.minutes = this.initialMinutes;
    const time = moment(new Date()).toDate();
    // this.estimatedTime = moment(new Date()).add(30, 'm').toDate();
    this.estimatedTime = moment(new Date(this.initialMinutes)).toDate();
    this.minutes = Math.ceil((this.estimatedTime.getTime() - time.getTime())/(60*1000));

    if(this.minutes < 0){
      // console.log("this is minutes ", this.minutes, this.estimatedTime.getTime(), time.getTime(), this.estimatedTime);
      this.timeExceed.emit({minutes: this.minutes, timeExceed: true, index: this.initialSeconds});
      // this.minutes = -(this.minutes);
    }
    this.seconds = 0;
    // console.log("this is time in minutes ",time.getTime(), this.estimatedTime.getTime(), this.minutes, this.initialSeconds);

    // Implement countdown logic
    const countdownInterval = setInterval(() => {
        // console.log("this is timerResolve ", this.timerResolve, this.initialSeconds);
        
        // if (this.seconds) {
        //   this.seconds--;
        //} else if (this.minutes) {
          this.minutes--;
          // this.seconds = 59;
        // }
        if(!this.timerResolve){
          console.log("inside timer resolve ", this.timerResolve);
          
        clearInterval(countdownInterval);
      }
    }, 60000);
  }

}
