<!-- updated-code  -->
<!-- this is home front view -->
<div *ngIf="roomLocationStatus == 'roomFrontView'">
    <div class="available-rooms d-flex" *ngFor="let obj of roomResponseArrayModel.RoomLists">
        <div class="row">
            <div class="col-lg-5 d-flex images-section">
                <div class="room-img-left">
                    <a [routerLink]="['/rooms',obj._id]">
                        <img [src]="obj.RoomImages[0]" alt=""
                            *ngIf="!globalService.validateIsEmpty(obj.RoomImages) && obj.RoomImages[0]; else  otherImage1" />
                        <ng-template #otherImage1>
                            <img src="assets/images/No_Image_Available.jpg" />
                        </ng-template>
                    </a>
                </div>
                <div class="room-img-right d-flex flex-column">
                    <div class="img-right-top">
                        <a [routerLink]="['/rooms',obj._id]">
                            <img [src]="obj.RoomImages[1]" alt=""
                                *ngIf="!globalService.validateIsEmpty(obj.RoomImages) && obj.RoomImages[1]; else  otherImage2" />
                            <ng-template #otherImage2>
                                <img src="assets/images/No_Image_Available.jpg" />
                            </ng-template>
                        </a>
                    </div>
                    <div class="img-right-bottom">
                        <a [routerLink]="['/rooms',obj._id]">
                            <img [src]="obj.RoomImages[2]" alt=""
                                *ngIf="!globalService.validateIsEmpty(obj.RoomImages) && obj.RoomImages[2]; else  otherImage3" />
                            <ng-template #otherImage3>
                                <img src="assets/images/No_Image_Available.jpg" />
                            </ng-template>
                        </a>
                    </div>
                </div>
            </div>

            <div class="col-lg-7 room-detail-guest">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="room-desc">
                            <div class="d-flex room-bio">
                                <div class="about-room-img">
                                    <img [src]="obj.RoomImages[0]" alt="" *ngIf="!globalService.validateIsEmpty(obj.RoomImages) && obj.RoomImages[0]; else  otherImageMobile"/>
                                    <ng-template #otherImageMobile>
                                        <img src="assets/images/No_Image_Available.jpg" />
                                    </ng-template>
                                </div>

                                <div class="about-room">
                                    <span class="font-20 medium-600 black">{{obj.RoomNameLangs[0].Value}}</span>
                                    <div class="review d-flex align-item">
                                        <span
                                            class=" entire-appartment font-14 normal grey-darker-shade display-block">{{obj.RoomType.RoomTypeLangs[0].Value}}</span>
                                        <div class="d-flex align-item star">
                                            <mat-icon class="star-icon">star</mat-icon>
                                            <span class="grey-darker-shade font-14 ml-1">4.94 ·107 {{'reviews'|translate}}</span>
                                        </div>
                                    </div>
                                    <div class="executive-button">
                                        <button mat-raised-button color="">{{'ExecutiveRoom'|translate}}</button>

                                    </div>
                                </div>
                            </div>

                            <span class="font-20 font-bold mt-4 display-block mobile-place-offers">{{'WhatThisPlaceOffers'|translate}}</span>
                            <div class=" d-flex hotel-offers">
                                <div class="place-offer">
                                    <ul>
                                        <li class="d-flex align-item mt-4" *ngFor="let data of obj.AmenitiNames">
                                            <mat-icon>{{data.AmenitiesIcon}}</mat-icon>
                                            <span
                                                class="ml-3 font-16 black medium-600">{{data.AmenitiesLangs.length>0?data.AmenitiesLangs[0].Value:data.AmenitiesIcon}}</span>
                                        </li>
                                    </ul>

                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="col-lg-6">

                        <div class="room-prize d-flex flex-column">
                            <div class="cancellation-button d-flex align-item">
                                <button class="font-12 normal pande">{{'FreeCancellationTill'|translate}} 17 Mar'22</button>
                            </div>
                            <div class=" end-section d-flex flex-column justify-content-between mt-5">
                            <div class="rooms-prize-deatil d-flex flex-column">
                                <span class="font-16 normal cancellation">
                                    <span class="secondary-text" [innerHTML]="currencyLogo"></span>{{obj.RoomPriceNight}}</span>
                                <span class="amount font-bold-large  mt-2">
                                    <span class="secondary-text" [innerHTML]="currencyLogo"></span>{{obj.RoomPriceNight}}</span>
                                <span class="taxes font-13 normal">+ taxes & fees</span>
                                <span class="fees font-13 normal">1 Room /night</span>
                            </div>
                            <div class="room-prize-buttons d-flex">
                                <div class="free-breakfast d-flex">
                                    <button mat-button class="d-flex align-item">
                                        <svg width="11" height="9" viewBox="0 0 11 9" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M9.99219 0C10.2734 0 10.5078 0.101562 10.6953 0.304688C10.8984 0.492188 11 0.726562 11 1.00781V2.50781C11 2.77344 10.8984 3.00781 10.6953 3.21094C10.5078 3.39844 10.2734 3.49219 9.99219 3.49219H8.98438V4.99219C8.98438 5.55469 8.78906 6.03125 8.39844 6.42188C8.00781 6.8125 7.53906 7.00781 6.99219 7.00781H3.99219C3.44531 7.00781 2.97656 6.8125 2.58594 6.42188C2.19531 6.03125 2 5.55469 2 4.99219V0H9.99219ZM9.99219 2.50781V1.00781H8.98438V2.50781H9.99219ZM0.992188 9V7.99219H9.99219V9H0.992188Z"
                                                fill="#34A853" />
                                        </svg>
                                        <span>{{'InclOfFreeBreakFast'|translate}}</span>
                                        
                                    </button>
                                </div>
                                <div class="book-now font-12 font-bold d-flex">
                                    <button mat-button class="d-flex align-item justify-center" [disabled]="obj.RoomsNumber.length == obj.Bookings.length || obj.RoomsNumber.length < obj.Bookings.length"
                                       (click)="bookNow(obj._id)" > <!--[routerLink]="['/rooms',obj._id]"-->
                                       {{'BookNow'|translate}}
                                    </button>

                                </div>
                                &nbsp;
                                <div class="cancellation-button d-flex align-item" *ngIf="obj.Bookings.length > 0 && obj.RoomsNumber.length > 0">
                                    <button mat-raised-button class="font-12 normal d-flex align-item justify-center pande">{{obj.RoomsNumber?.length == obj.Bookings?.length?searchData.location=='roomSearch'?'Fully booked till ' + checkout?.toString()?.slice(0,10) :'Fully booked till ' + showDate:obj.RoomsNumber.length > obj.Bookings.length?searchData.location=='roomSearch'?(obj.RoomsNumber.length - obj.Bookings.length) + ' room left' :(obj.RoomsNumber.length - obj.Bookings.length) + ' room left':searchData.location=='roomSearch'?'Fully booked till ' + checkout?.toString()?.slice(0,10) :'Fully booked till ' + showDate}}</button> <!---->
                                </div>

                            </div>
                        </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div class="pagination" *ngIf="!globalService.validateIsEmpty(roomResponseArrayModel.RoomLists)">
        <span *ngIf="roomResponseArrayModel.RoomLists.length">
            <mat-paginator [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
                (page)="pageEvent = getPaginatorData($event)" *ngIf="length > 0">
            </mat-paginator>
        </span>
    </div>
</div>
<!-- updated-code end -->

<div  *ngIf="roomLocationStatus=='noResultFound'">
    <div class="no-result-img no-result">
        <img class="" src="../../../../assets/images/no-results.png" alt="">
    </div>
<h2 class="no-result mtb-2">{{'SorryNoResultFound'|translate}}(</h2>
<p class="no-result">{{'WeAreSorryWhatYouLookingForPleasetryAnotherWay'|translate}}</p>
</div>



<!-- //Home Page -->
<div class="inner-loader" *ngIf="showLoader">
    <mat-progress-spinner class="tp-margin" [color]="color" [mode]="mode">
    </mat-progress-spinner>
    
</div>


<!-- Home list -->
<div class="home-page row" *ngIf="roomLocationStatus == 'home'">
    <div class="gallery-item-list col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-4"
        *ngFor="let obj of roomResponseArrayModel.RoomLists | slice:0:4;">
        <div class="wrapper-list-thumb">
            <div class="img-info-on-hover">
                <div class="room-img-wrapper">
                    <a [routerLink]="['/rooms',obj._id]">
                        <img [src]="obj.RoomImages[0]" *ngIf="obj.RoomImages && obj.RoomImages[0];else  otherImage" />

                        <ng-template #otherImage>
                            <img src="assets/images/No_Image_Available.jpg" />

                        </ng-template>
                    </a>


                </div>
                <div class="img-overlay" *ngIf="globalService.hasLoginInAuthToken()">
                    <div class="overlay-items mb-4">
                        <div class="overlay-btn mr-3">
                            <button mat-button [routerLink]="['/rooms',obj._id]">{{'BookNow'|translate}}</button>
                        </div>
                        <button class="icon1 mr-3">
                            <mat-icon>favorite_border</mat-icon>
                        </button>
                        <button class="icon2 mr-3">
                            <mat-icon [routerLink]="['/rooms',obj._id]">info</mat-icon>
                        </button>
                    </div>

                </div>
            </div>
            <div class="list-thumb-content">
                <p class="type-info  secondary-text"
                    *ngIf="obj.RoomType && obj.RoomType.RoomTypeLangs && obj.RoomType.RoomTypeLangs.length;else otherRoomType">
                    {{ obj.RoomType.RoomTypeLangs[0].Value }}</p>

                <ng-template #otherRoomType>
                    <p class="type-info  secondary-text">
                        {{ 'NotAvailable'|translate }}</p>
                </ng-template>
                <p class="title-info primary-text"
                    *ngIf="obj.RoomNameLangs && obj.RoomNameLangs.length;else otherRoomName">
                    {{ obj.RoomNameLangs[0].Value }}
                </p>
                <ng-template #otherRoomName>
                    <p class="title-info primary-text">
                        {{ 'NotAvailable'|translate }}
                    </p>
                </ng-template>
            </div>
        </div>
    </div>

</div>
<!-- //Home list -->
<!-- Home Page Menu Page -->
<div class="home-page row" *ngIf="roomLocationStatus == 'homePageMenu'">
    <div class="gallery-item-list col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-4"
        *ngFor="let obj of roomResponseArrayModel.RoomLists">
        <div class="wrapper-list-thumb position-relative wrapper-list-thumb-manage">
            <div class="delete-image"> <i class="material-icons" (click)="deactivateFromHome(obj._id)"
                    [appAccessCheck]="'homepage-menu'" [givenButton]="'delete'">delete</i></div>
            <div class="room-img-wrapper">
                <img [src]="obj.RoomImages[0]" *ngIf="obj.RoomImages && obj.RoomImages[0];else  otherImage" />
                <ng-template #otherImage>
                    <img src="assets/images/No_Image_Available.jpg" />
                </ng-template>
            </div>
            <div class="list-thumb-content">
                <p class="type-info  secondary-text"
                    *ngIf="obj.RoomType && obj.RoomType.RoomTypeLangs && obj.RoomType.RoomTypeLangs.length;else otherRoomType">
                    {{ obj.RoomType.RoomTypeLangs[0].Value }}</p>

                <ng-template #otherRoomType>
                    <p class="type-info  secondary-text">
                        {{ 'NotAvailable'|translate }}</p>
                </ng-template>
                <p class="title-info primary-text"
                    *ngIf="obj.RoomNameLangs && obj.RoomNameLangs.length;else otherRoomName">
                    {{ obj.RoomNameLangs[0].Value }}
                </p>
                <ng-template #otherRoomName>
                    <p class="title-info primary-text">
                        {{ 'NotAvailable'|translate }}
                    </p>
                </ng-template>
            </div>
        </div>
    </div>
</div>


<!---Room Manage Page-->
<div class="room-manage-page row" *ngIf="roomLocationStatus == 'roomManagePage'">
    <!---->
    <div class="gallery-item-list col-lg-3 col-md-4 col-sm-6 col-xs-12"
        *ngFor="let obj of roomResponseArrayModel.RoomLists">
        <div class="wrapper-list-thumb">
            <div class="room-img-wrapper">
                <img [src]="obj.RoomImages[0]" *ngIf="obj.RoomImages && obj.RoomImages[0]; else otherImage" />
                <ng-template #otherImage>
                    <img src="assets/images/No_Image_Available.jpg" />
                </ng-template>
            </div>
            <div class="list-thumb-content">
                <p class="type-info  secondary-text"
                    *ngIf="obj.RoomType && obj.RoomType.RoomTypeLangs && obj.RoomType.RoomTypeLangs.length;else otherRoomType">
                    <!---->
                    {{ obj.RoomType.RoomTypeLangs[0].Value }}
                </p>

                <ng-template #otherRoomType>
                    <p class="type-info  secondary-text">
                        {{ 'NotAvailable'|translate }}</p>
                </ng-template>
                <p class="title-info primary-text"
                    *ngIf="obj.RoomNameLangs && obj.RoomNameLangs.length;else otherRoomName">
                    {{ obj.RoomNameLangs[0].Value }}
                </p>
                <ng-template #otherRoomName>
                    <p class="title-info primary-text">
                        {{ 'NotAvailable'|translate }}
                    </p>
                </ng-template>
            </div>
            <div class="button-section">
                <button class="btn btn-primary" type="button" (click)="detailRoomView(obj._id)"
                    [appAccessCheck]="'rooms'" [givenButton]="'view'"><i class="material-icons">remove_red_eye</i>{{'ViewDetail'|translate}}</button>
                <button class="btn btn-secondary" type="button" (click)="editRoomView(obj._id)"
                    [appAccessCheck]="'rooms'" [givenButton]="'edit'"><i class="material-icons">create</i>{{'Edit'|translate}}</button>
            </div>
        </div>
    </div>
    <div class="pagination" *ngIf="roomResponseArrayModel.RoomLists">
        <span *ngIf="roomResponseArrayModel.RoomLists.length">
            <mat-paginator [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
                (page)="pageEvent = getPaginatorData($event)" *ngIf="length > 0">
            </mat-paginator>
        </span>
    </div>
</div>

<!---//Room Manage Page-->
