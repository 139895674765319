import { Directive, Input, HostBinding, OnChanges } from '@angular/core';
import { MasterService } from '../services/master.service';

@Directive({
  selector: '[SiteLogo]'
})
export class SiteLogoDirective implements OnChanges{
  siteLogoSubscribe$;
  @Input()
  logoImgUrl: string;

  @HostBinding('src')
  imageSource;
  constructor(private masterService:MasterService) { }

  ngOnInit() {
  
  }
  // Lifecycle hook implemented here to update imageSource whenever     // the card number changes
  ngOnChanges() {
    //console.log(this.logoImgUrl);
    this.imageSource =  this.logoImgUrl;
  
  }

}
