import { Injectable } from "@angular/core";
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  UrlTree
} from "@angular/router";
import { Observable } from "rxjs";
import { AclRedirection } from "../services/acl-redirection";
import { of } from "rxjs";
import { JwtService } from "../services/jwt.service";
import { GlobalService } from "../services/global.service";
import { PermissionModel } from "../models/common-request-response";

@Injectable()
export class AclDemoResolver implements Resolve<any> {
  public permissionModel: PermissionModel = new PermissionModel();
  constructor(
    private router: Router,
    private aclRedirection: AclRedirection,
    private jwtService: JwtService,
    private globalService: GlobalService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (window.localStorage["bulkPermissions"]) {
      //check if Bulpermsiisons Exists----
      const adminPermissions = JSON.parse(
        window.localStorage["bulkPermissions"]
      );

      //Check Current Route is Accessible
      let splitCurrent = state.url.split("/");
      console.log(splitCurrent[splitCurrent.length - 1]);
      let findCurrentIndex = adminPermissions
        .map(item => item.nameKey)
        .indexOf(splitCurrent[splitCurrent.length - 1]);
      if (findCurrentIndex != -1) {
        if (
          !this.globalService.validateIsEmpty(
            adminPermissions[findCurrentIndex].permissions
          )
        ) {
          this.permissionModel = this.globalService.jsonConvert.deserializeObject(
            adminPermissions[findCurrentIndex].permissions[0],
            PermissionModel
          );
          let permissions = this.permissionModel;
          // console.log("check permissions");
          // console.log(permissions);
          if (
            permissions.full ||
            permissions.manage ||
            permissions.viewOnly ||
            permissions.bookOnly
          ) {
            const urlToParse =
              splitCurrent.slice(0, splitCurrent.length - 1).join("/") +
              "/" +
              splitCurrent[splitCurrent.length - 1];

            console.log("current route found------");
            // return this.router.navigate([urlToParse]);
            return true;
          } else {
            console.log(
              "It is False donot have access" +
                splitCurrent[splitCurrent.length - 1]
            );
          }
        }
      }

      //Loop through all children controllers-------------------------------
      for (let i = 0; i < route.routeConfig.children.length; i++) {
        // if (route.routeConfig.children[i] === 3) { continue; }
        let findIndex = adminPermissions
          .map(item => item.nameKey)
          .indexOf(route.routeConfig.children[i].path);
        if (findIndex != -1) {
          if (
            !this.globalService.validateIsEmpty(
              adminPermissions[findIndex].permissions
            )
          ) {
            this.permissionModel = this.globalService.jsonConvert.deserializeObject(
              adminPermissions[findIndex].permissions[0],
              PermissionModel
            );
            let permissions = this.permissionModel;
            // console.log("check permissions");
            // console.log(permissions);
            if (
              permissions.full ||
              permissions.manage ||
              permissions.viewOnly ||
              permissions.bookOnly
            ) {
              //If No permissions exists--
              const split = state.url.split("/");
              const urlToParse =
                split.slice(0, split.length - 1).join("/") +
                "/" +
                route.routeConfig.children[i].path;

              console.log(urlToParse);
              const tree: UrlTree = this.router.parseUrl(urlToParse);
              return this.router.navigate([urlToParse]);

              break;
            } else {
              console.log(route.routeConfig.children[i].path + "<<<False");
              //Redirect to second route----------------------------------
            }
          }
        } else {
          console.log("Route Not Found---------------");
          continue;
        }
      }

      console.log("Next execution------");
    } else {
      return true;
    }
  }
}
