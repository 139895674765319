import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
// import { CheckinModalComponent } from '../../modals/checkin-modal/checkin-modal.component';

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"]
})
export class HeaderComponent implements OnInit {
  constructor(
    private router: Router,
    private dialog: MatDialog,
    private translate: TranslateService
  ) {}

  title = { name: "Viva La fonterra" };
  language = "en";
  hotelId = "1234";

  concatenate(hotelId, language) {
    return hotelId + "-" + language;
  }

  @Output() voted = new EventEmitter<boolean>();
  didVote = false;

  vote(agreed: boolean) {
    console.log(agreed);
    this.voted.emit(agreed);
    this.didVote = true;
  }

  switchLanguage(language: string) {
    // this.language = language;
    // let combinedValue = this.concatenate('1234',this.language);
    // this.translate.use(combinedValue);
  }

  ngOnInit() {
    // let combinedValue = this.concatenate('1234',this.language);
    // this.translate.setDefaultLang(combinedValue);
  }

  openCheckInModal(): void {
    // this.dialog.open(CheckinModalComponent, {
    //   width: '440px',
    //   height: 'auto',
    //   panelClass: 'login-modalbox',
    //   disableClose: true
    // });
  }

  test() {
    console.log("Yess");
    this.router.navigate(["company/products"]);
  }
}
