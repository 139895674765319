import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { Globals } from "../../globals";
import { MasterService } from "../../services/master.service";
import { ManageBrandingModel } from "../../models/request/manageBranding.model";
import { GlobalService } from "../../services/global.service";

@Component({
  selector: "app-language-save",
  templateUrl: "./language-save.component.html",
  styleUrls: ["./language-save.component.scss"]
})
export class LanguageSaveComponent implements OnInit {
  @Input() lang: string = this.globals.langSaveProperty;
  @Input() popupStatus: boolean;
  @Output() selectedLang = new EventEmitter();
  public langDisplayPopup = this.globals.langSaveValue;
  brandingSubscribe$;
  public manageBrandingModel: ManageBrandingModel = new ManageBrandingModel();
  public hotelLanguages: Array<Object>;
  public headerLangChangeEvent$;
  public globalList = this.globals.languageList;
  constructor(
    public globals: Globals,
    private masterService: MasterService,
    public globalService: GlobalService,
  ) {}

  ngOnInit() {
    // Fetch Manage Branding Details  Title Default Language
    this.fetchManageBranding();
    if (
      window.localStorage["langSaveProperty"] &&
      window.localStorage["langSaveValue"]
    ) {
      this.globals.langSaveValue = window.localStorage["langSaveValue"];
      this.globals.langSaveProperty = window.localStorage["langSaveProperty"];
      this.langDisplayPopup = this.globals.langSaveValue;
    }
    
  }
 

  ngOnDestroy() {
    if (this.brandingSubscribe$) {
      this.brandingSubscribe$.unsubscribe();
    }
  }

  fetchManageBranding() {
    this.masterService.getManageBranding({
      Lang: this.globals.hotelLangTranslate,
      Subdomain: this.globalService.getHostname()
    });
    //Subscribe to Branding
    this.brandingSubscribe$ = this.masterService.manageBrandingData.subscribe(
      response => {
        if (response.Data) {
          //console.log("Languages List----"+JSON.stringify(response.Data));
          this.manageBrandingModel = this.globalService.jsonConvert.deserializeObject(
            response.Data,
            ManageBrandingModel
          );

          //Check default language if exists And Languages
          if (this.manageBrandingModel.Languages) {
            // console.log(this.manageBrandingModel.Languages);
            // Fetch Language from Hotel folder
            this.hotelLanguages = this.manageBrandingModel.Languages;
          }
          // console.log(this.hotelLanguages);
        } else {
          this.globalService.handleApiError(response.error);
        }
      },
      error => {
        this.globalService.showLoader = false;
        this.globalService.handleApiError(error);
      }
    );
  }

  switchLanguage(event) {
    if (!this.popupStatus) {
      window.localStorage["langSaveProperty"] = event.Property;
      window.localStorage["langSaveValue"] = event.Value;
      this.globals.langSaveProperty = event.Property;
      this.globals.langSaveValue = event.Value;
    } else {
      this.langDisplayPopup = event.Value;
    }
    this.selectedLang.emit(event.Property); //Fire Child Event
  }
}
