import { Pipe, PipeTransform } from "@angular/core";
import { Globals } from "../globals";

@Pipe({
  name: "timeFormat"
})
export class TimeFormatPipe implements PipeTransform {
  constructor(public globals: Globals) {}
  formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }

  transform(item: any): any {
    let timezoneSelect = "";
    let timeFormat = "";
    if (window.localStorage["Timezone"]) {
      timezoneSelect = window.localStorage["Timezone"];
    } else {
      timezoneSelect = this.globals.Timezone;
    }

    let date1 = new Date(item).toLocaleString("en-US", {
      timeZone: timezoneSelect
    });
    let date = new Date(date1);

    // console.log(date);
    if (window.localStorage["TimeFormat"]) {
      timeFormat = window.localStorage["TimeFormat"];
    } else {
      timeFormat = this.globals.TimeFormat;
    }
    if (timeFormat == "hh:mm tt") {
      return this.formatAMPM(date);
    } else {
      var hours = date.getHours();
      var minutes = date.getMinutes();
      return hours + ":" + minutes;
    }
  }
}
