import { Directive, Input, ElementRef } from "@angular/core";
import { SharedService } from "src/app/shared.service";
import {
  PermissionModel,
  AdminPermissionResponseModel
} from "../models/common-request-response";
import { GlobalService } from "../services/global.service";
import { JwtService } from "../services/jwt.service";

@Directive({
  selector: "[appAccessCheck]"
})
export class AccessCheckDirective {
  permissionChangeEvent$;
  // @Input()
  // givenRoute: string;

  @Input("appAccessCheck") givenRoute: string;

  @Input() givenButton: string;

  private adminPermissionResponseModel: AdminPermissionResponseModel = new AdminPermissionResponseModel();
  public permissionModel: PermissionModel = new PermissionModel();

  constructor(
    private el: ElementRef,
    private sharedService: SharedService,
    public globalService: GlobalService,
    private jwtService: JwtService
  ) {}
  ngOnInit() {
    this.checkPermissions("", this.givenRoute);
  }
  ngAfterViewInit() {
    this.permissionChangeEvent$ = this.sharedService.permissionEvent.subscribe(
      (data: any) => {
        // console.log("Subscribed Event-----Directive " + this.givenRoute);
        console.log(data);
        if (!this.globalService.validateIsEmpty(data)) {
          window.localStorage["bulkPermissions"] = JSON.stringify(data);
        }

        this.adminPermissionResponseModel = this.globalService.jsonConvert.deserializeObject(
          data,
          AdminPermissionResponseModel
        );
        this.checkPermissions(
          this.adminPermissionResponseModel,
          this.givenRoute
        );
      }
    );
  }

  ngOnDestroy() {
    if (this.permissionChangeEvent$) {
      this.permissionChangeEvent$.unsubscribe();
    }
  }

  checkPermissions(adminPermissionResponseModel, givenRoute) {
    if (!this.jwtService.getUserRole) {
      this.el.nativeElement.remove();
    } else if (this.jwtService.getUserRole() == "admin") {
      // this.el.nativeElement.style.display = "inline-block";
      return true;
    } else {
      // console.log(
      //   givenRoute +
      //     " Check Data Emit Length " +
      //     adminPermissionResponseModel.length
      // );
      // console.log(givenRoute);
      if (!this.globalService.validateIsEmpty(adminPermissionResponseModel)) {
        // console.log("Read From Direct Api Response");
        let findIndex = adminPermissionResponseModel
          .map((item: { nameKey: any; }) => item.nameKey)
          .indexOf(givenRoute);
        if (findIndex != -1) {
          // console.log(adminPermissionResponseModel);
          // console.log(
          //   givenRoute +
          //     " permissions " +
          //     adminPermissionResponseModel[findIndex].permissions
          // );
          if (adminPermissionResponseModel[findIndex].permissions) {
            this.permissionModel = this.globalService.jsonConvert.deserializeObject(
              adminPermissionResponseModel[findIndex].permissions[0],
              PermissionModel
            );
            let permissions = this.permissionModel;
            // console.log(
            //   "Check Related " + givenRoute + " Permissions " + permissions
            // );
            // console.log(permissions);
            this.permission(permissions);
          } else {
            // console.log("Permission not set " + givenRoute);
            this.el.nativeElement.style.display = "none";
          }
        } else {
          // console.log("route not found " + givenRoute);
          this.el.nativeElement.style.display = "none";
        }
      } else {
        // console.log("Read From Local Storage Bulk permissions");
        let adminPermissions = JSON.parse(
          window.localStorage["bulkPermissions"]
        );
        if (!this.globalService.validateIsEmpty(adminPermissions)) {
          let findIndex = adminPermissions
            .map((item: { nameKey: any; }) => item.nameKey)
            .indexOf(givenRoute);
          if (findIndex != -1) {
            // console.log(
            //   givenRoute +
            //     " permissions " +
            //     adminPermissions[findIndex].permissions
            // );
            if (adminPermissions[findIndex].permissions) {
              this.permissionModel = this.globalService.jsonConvert.deserializeObject(
                adminPermissions[findIndex].permissions[0],
                PermissionModel
              );
              let permissions = this.permissionModel;
              // console.log(
              //   "Check Related " + givenRoute + " Permissions " + permissions
              // );
              // console.log(permissions);

              this.permission(permissions);
            } else {
              // console.log("Permission not set " + givenRoute);
              this.el.nativeElement.style.display = "none";
            }
          } else {
            // console.log("If route not found " + givenRoute);
            this.el.nativeElement.style.display = "none";
          }
        } else {
          // console.log("Data emit is empty " + givenRoute);
          this.el.nativeElement.style.display = "none";
        }
      }
    }
  }

  permission(permissions) {
    //For Route Permissions

    if (this.givenRoute) {
      // console.log("Route Check");
      // console.log(this.givenRoute);
      // console.log(this.givenButton);
      if (!this.givenButton) {
        if (
          permissions.full ||
          permissions.manage ||
          permissions.viewOnly ||
          permissions.bookOnly
        ) {
          // this.el.nativeElement.style.display = "none";
          this.el.nativeElement.style.display = "block";
        } else {
          this.el.nativeElement.style.display = "none";
          // this.el.nativeElement.remove();
        }
      } else {
        // console.log("Button check");
        if (this.givenButton !== "add") {
          // console.log(this.givenButton);
          if (permissions.full || permissions.manage) {
            // console.log("this is inside if with manage or full being true")
            this.el.nativeElement.style.display = "inline-block";
          } else {
            // console.log("this is inside else")
            this.el.nativeElement.style.display = "none";
          }
        }

        if (this.givenButton == "edit") {
          // console.log(this.givenButton);
          if (permissions.full || permissions.manage) {
            this.el.nativeElement.style.display = "inline-block";
          } else {
            this.el.nativeElement.style.display = "none";
          }
        }

        if (this.givenButton == "add") {
          if (permissions.full) {
            this.el.nativeElement.style.display = "inline-block";
          } else {
            this.el.nativeElement.style.display = "none";
          }
        }
      }
    }
  }
}
