import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MasterService } from '../../services/master.service';
import { MatDialog } from '@angular/material/dialog';
import { GlobalService } from '../../services/global.service';
import { takeUntil } from 'rxjs/operators';
import { UserInfoInterface } from 'src/app/hotel-admin/components/messaging/messaging.component';
import { JwtService } from '../../services/jwt.service';
import jwt_decode from 'jwt-decode';
import { PrintRestaurantOrderComponent } from '../../modals/print-restaurant-order/print-restaurant-order.component';


@Component({
  selector: 'app-restro-order-history-admin',
  templateUrl: './restro-order-history-admin.component.html',
  styleUrls: ['./restro-order-history-admin.component.scss']
})
export class RestroOrderHistoryAdminComponent implements OnInit {
  @Output() toggleOrders: EventEmitter<void> = new EventEmitter<void>(); 
  @Input() orderId: string | null = null;
  displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];
  dataSource = ELEMENT_DATA;
  dataSource2
  currencyLogo
  orderDetails
  requestId
  currencyAbri
  public toggle = true;

  location
  RestaurantOrder
  public loggedInUserInfo: UserInfoInterface;
  BookingUserId
  UserId
  public filterDate: { fromDate: any; toDate: any; };

  displayedColumnss = ['item', 'cost'];
  transactions: Transaction[] = [
    // {item: 'Beach ball', cost: 4},
    // {item: 'Towel', cost: 5},
    // {item: 'Frisbee', cost: 2},
    // {item: 'Sunscreen', cost: 4},
    // {item: 'Cooler', cost: 25},
    // {item: 'Swim suit', cost: 15},
  ];

  panelOpenState = false;

  constructor( public masterService: MasterService,
    private dialog: MatDialog,
    public globalService: GlobalService,
    private jwtService: JwtService,
    ) { 
      this.loggedInUserInfo = jwt_decode(this.jwtService.getToken());
    }

  ngOnInit(): void {
    this.filterDate = JSON.parse(localStorage.getItem('viewOrderFilterData'));
   
    this.requestId = this.orderId;
    this.fetchOrderDetail()
    this.getHotelsName();
    this.OrderHistory();
  }
  getHotelsName() {
    
    const data = {
    };
    this.masterService.getHotelName(data).subscribe((response) => {
      if (response) {
        console.log('this is currency Logo of Hotel');
        console.log(response);
        window.localStorage['CurrencyLogo'] = response[0].symbol;
        window.localStorage['CurrencyAbri'] = response[0].abbreviation;
        window.localStorage['Currency'] = response[0].currency;
        this.currencyLogo = response[0].symbol;
        this.currencyAbri = response[0].abbreviation;
        this.globalService.setHotelNameUpdate(response[1]);
      }
      (error) => {
        this.globalService.handleApiError(error);
      };
    });
  } 
  
  printOrder(id,viewOrder){
    console.log("this is id ", id);
    let dialogRef = this.dialog.open(PrintRestaurantOrderComponent, {
      width: "550px",
      height: "538px",
      panelClass: "cm-md-modal",
      disableClose: true,
      data: {
        viewOrder: viewOrder,
        requestId:id,
      }
    });
  }
  

  fetchOrderDetail(){
    this.masterService
      .getRestaurantOrderDetail({requestId : this.requestId})
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe(
        response => {
          if(response.Status){
            console.log("this is response ", response.Data[0]);
            this.orderDetails = response.Data[0];
            this.BookingUserId = this.orderDetails.BookingUserId;
            this.UserId = this.orderDetails.UserId
            let dataSourcePrint : PeriodicElement[] = [];
              for (let item of response.Data[0].ResturantItemName) {
                for (let itemName of item.ResturantItems) {
                  for (let id of response.Data[0].Items) {
                    console.log("this is id ", itemName._id, id._id);
                    if (itemName._id == id._id) {
                      const quantity = id.Quantity;
                      const price = id.Price
                      const itemNameValue = itemName.ResturantItemsNameLang[0].Value;
                      console.log("this is quantity and name ", quantity, itemNameValue);
                      
                      dataSourcePrint.push({
                        Itemname: itemNameValue,
                        Quantity: Number(quantity),
                        Price:Number(price),
                        Amount:quantity * price
                      });
                    }
                  }
                }
              }
              this.fetchRestaurantOrder() 

              this.dataSource = dataSourcePrint.slice(0);
              console.log("this is data source for print", this.dataSource);
              
          }
          else {
            console.log("this is response ", response); 
            this.globalService.showLoader = false;
          }
        },
        error => {
          console.log("this is inside error ", error);
          this.globalService.showLoader = false;
          this.globalService.handleApiError(error);
        }
      );
  }



  fetchRestaurantOrder() {
    this.globalService.showLoader = true; //Set To True

    const decoded = this.jwtService.decodeJwttoken(window.localStorage["CheckIn_TOKEN"]);
    console.log(decoded);
    let data; 
    // console.log("this.filterDate !== null && this.location == 'previous' ,", this.filterDate !== null && this.location == 'previous',this.filterDate, this.location)
    if( this.filterDate !== null && this.location =='filter'  ){
        data = {
        BookingUserId: this.BookingUserId?this.BookingUserId:this.UserId,
        HotelId: this.loggedInUserInfo.HotelId,
        Location: 'filter',
        FromDate: this.filterDate.fromDate,
        ToDate: this.filterDate.toDate,
      }
    }else{
        data = {
          BookingUserId: this.BookingUserId?this.BookingUserId:this.UserId,
          HotelId: this.loggedInUserInfo.HotelId,
         Location: 'previous'
      }
    }
    console.log("this is data of get order api ", data);
    this.masterService
      .getRestaurantOrderList(data)
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe(
        response => {
          if (response.Status) {
            this.RestaurantOrder = response.Data;
            console.log("this is restaurant order detail ", this.RestaurantOrder);
            // this.currentCount.emit({data:this.RestaurantOrder.length})
            // this.calculateTotalAmounts();

            let dataSource : Transaction[] = [];


            for (let i = 0; this.RestaurantOrder.length > i; i++) {
              // ... (existing logic to construct 'data' and 'internalData')
          
              for (let item of this.RestaurantOrder[i].ResturantItemName) {
                  for (let itemName of item.ResturantItems) {
                      for (let id of this.RestaurantOrder[i].Items) {
                          if (itemName._id === id._id) {
                              const quantity = id.Quantity;
                              const price = id.Price;
                              const itemNameValue = itemName.ResturantItemsNameLang[0].Value;
          
                              // Construct the transaction data
                              dataSource.push({
                                  item: itemNameValue,
                                  price: Number(price) * Number(quantity),
                              });
                          }
                      }
                  }
              }
          
          }







          //   for(const data of response.Data){
          //   for (const item of data.ResturantItemName) {
          //     for (const itemName of item.ResturantItems) {
          //       for (const id of data.Items) {
          //         console.log("this is id ", itemName._id, id._id);
          //         if (itemName._id === id._id) {
          //           const quantity = id.Quantity;
          //           const price = id.Price
          //           const itemNameValue = itemName.ResturantItemsNameLang[0].Value;
          //           console.log("this is quantity and name ", quantity, itemNameValue);
                    
          //           dataSource.push({
          //             item: itemNameValue,
          //             price:Number(price)*Number(quantity),
          //           });
          //         }
          //       }
          //     }
          //   }
          // }
          this.dataSource2 = dataSource.slice(0);

          if(this.filterDate!== null){
            this.location = 'filter'
            }else{
             this.location = 'previous'
            }
            this.globalService.showLoader = false;

          } else {
            console.log("this is response ", response);
            this.globalService.showLoader = false;
          }

        },
        error => {
          console.log("this is inside error ", error);
          
          this.globalService.showLoader = false;
          this.globalService.handleApiError(error);
        }
      );
  }

  filterSet(event){
    this.filterDate = JSON.parse(localStorage.getItem('viewOrderFilterData'));
    if(this.filterDate!== null){
      this.location = 'filter'
      }else{
       this.location = 'previous'
      }

      if(event){
        this.fetchRestaurantOrder();
      }
  }
  selectdDateFilter(event){
    if(event.data == false){
      this.location = 'previous';
      this.fetchRestaurantOrder()

      this.toggle = !this.toggle;
    }else{
     this.filterSet(event)
      this.location = 'filter';
      this.fetchRestaurantOrder()
      this.toggle = !this.toggle;

      console.log("this is event and location", event, this.location, this.toggle);
    }
  }
  hideCalendarFilter(): void {
    if(this.filterDate!== null){
      this.location = 'filter'
      }else{
       this.location = 'previous'
      }
    this.toggle = false;
    this.fetchRestaurantOrder();

  }

  OrderHistory(){
    window.localStorage.removeItem('viewOrderFilterData');
  }

 onOrderToggleClick() {
  this.toggleOrders.emit();
  } 

  getTotalCost() {
    return this.transactions.map(t => t.price).reduce((acc, value) => acc + value, 0);
  }

  ngOnDestroy(){

  }

}


export interface PeriodicElement {
  Itemname: string;
  Price: number;
  Quantity: number;
  Amount: number;
}

// const ELEMENT_DATA: PeriodicElement[] = [
//   {position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H'},
//   {position: 2, name: 'Helium', weight: 4.0026, symbol: 'He'},
//   {position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li'},
//   {position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be'},
// ];

export interface Transaction {
  item: string;
  price: number;
}
const ELEMENT_DATA: PeriodicElement[] = [];