import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MasterService } from '../../services/master.service';
import { GlobalService } from '../../services/global.service';
import { JwtService } from '../../services/jwt.service';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-guest-order-list',
  templateUrl: './guest-order-list.component.html',
  styleUrls: ['./guest-order-list.component.scss']
})
export class GuestOrderListComponent implements OnInit {
  public RestaurantOrder :any[]= [];
  public totalAmounts: number[] = [];
  public filterDate: { fromDate: any; toDate: any; };
  @Input() location: string; 
  @Output() currentCount = new EventEmitter();
  public currencyLogo = window.localStorage["CurrencyLogo"];
  public currencyAbri = window.localStorage["CurrencyAbri"];
  constructor( private masterService: MasterService,public globalService: GlobalService, public jwtService: JwtService, public route: ActivatedRoute,
    ) { }

  ngOnInit(): void {
    this.filterDate = JSON.parse(localStorage.getItem('viewOrderFilterData'));
    console.log("this is date from guest order list ", this.filterDate);
    this.fetchRestaurantOrder();
    console.log("this is location ", this.location);
  }

  fetchRestaurantOrder() {
    this.globalService.showLoader = true; //Set To True

    const decoded = this.jwtService.decodeJwttoken(window.localStorage["CheckIn_TOKEN"]);
    console.log(decoded);
    let data; 
    console.log("this.filterDate !== null && this.location == 'previous' ,", this.filterDate !== null && this.location == 'previous',this.filterDate, this.location)
    if(this.filterDate !== null && this.location == 'filter' ){
        data = {
        BookingUserId: decoded['UserId'],
        HotelId: decoded['HotelId'],
        Location: 'filter',
        FromDate: this.filterDate.fromDate,
        ToDate: this.filterDate.toDate,
      }
    }else{
        data = {
        BookingUserId: decoded['UserId'],
        HotelId: decoded['HotelId'],
        Location: this.location
      }
    }
    console.log("this is data of get order api ", data);
    this.masterService
      .getRestaurantOrderList(data)
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe(
        response => {
          if (response.Status) {
            this.RestaurantOrder = response.Data;
            console.log("this is restaurant order detail ", this.RestaurantOrder);
            this.currentCount.emit({data:this.RestaurantOrder.length})
            this.calculateTotalAmounts();
            this.globalService.showLoader = false;

          } else {
            console.log("this is response ", response);
            this.globalService.showLoader = false;
          }

        },
        error => {
          console.log("this is inside error ", error);
          
          this.globalService.showLoader = false;
          this.globalService.handleApiError(error);
        }
      );
  }
  
  ngOnDestroy() {
    
  }
  calculateTotalAmounts() {
    this.totalAmounts = this.RestaurantOrder.map((order) => {
      return order.Items.reduce((acc, item) => acc + item.Price * item.Quantity, 0).toFixed(2);
    });
  }

}
