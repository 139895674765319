import { EventEmitter, Injectable, Inject } from "@angular/core";
import { MasterService } from "./master.service";
import { GlobalService } from "./global.service";
import { HttpEventType, HttpResponse } from "@angular/common/http";

// import * as AWS from "aws-sdk/global";
// import * as S3 from "aws-sdk/clients/s3";
var global = global || window;
@Injectable({
  providedIn: "root"
})
export class AmazonWebService {
  public processCompleted = new EventEmitter<any>();
  public downloadUrlFetched = new EventEmitter<object>();
  public deleteProcessCompleted = new EventEmitter<any>();

  constructor(
    private masterService: MasterService,
    private globalService: GlobalService
  ) {
    // Empty
  }

  public uploadObject(key: string, file: File, access: string, folder: string) {
    let filecheck = file.name.replace(/\s/g, "");
    filecheck = file.name.replace(/[^a-zA-Z0-9.]/g, "-");
    let uniqueFileName = Date.now() + filecheck;
    let data = {
      ReqMethod: "putObject",
      FileName: uniqueFileName,
      ContentType: file.type,
      ACL: access,
      Folder: this.globalService.getHostname() + "/" + folder
    };

    this.masterService.getAwsSignedUrl(data).subscribe(res => {
      if (res.Status == true) {
        this.masterService.putObjectIntoBucket(res.Data, file).subscribe(
          event => {
            //console.log("REponse Response "+res);

            if (event.type === HttpEventType.UploadProgress) {
              //this.progress.percentage = Math.round(100 * event.loaded / event.total);
            } else if (event instanceof HttpResponse) {
              // console.log("Check event Status"+event.status);

              if (event.status == 200) {
                console.log("File is completely uploaded!");
                this.processCompleted.emit({
                  error: null,
                  data: {
                    Location: uniqueFileName,
                    OriginalName: file.name,
                    FolderName: folder
                  }
                });
              }
            }
            /*if (res.status == 200) {
                      console.log("Emit Reponse -------------");
                      this
                          .processCompleted
                          .emit({
                              error: null,
                              data: {
                                  Location: uniqueFileName,
                                  OriginalName: file.name,
                              }
                          });
                  }
                  else {
                    console.log("Emit Error -------------");
                      this.globalService.handleErrorMessage('Error occured')
                      this
                          .processCompleted
                          .emit({
                              error: 'Error occured',
                              data: null
                          });
                  }*/
          },
          error => {
            this.globalService.handleErrorMessage("Error occured");
            this.processCompleted.emit({
              error: "Error occured",
              data: null
            });
          }
        );
      } else {
        this.globalService.handleErrorMessage("Error occured");
        this.processCompleted.emit({
          error: "Error occured",
          data: null
        });
      }
    });
  }

  // uploadFile(file: File, folder: string) {
  //   let uniqueFileName = Date.now() + file.name.replace(/ /g, "");
  //   const contentType = file.type;

  //   const bucket = new S3({
  //     accessKeyId: "",
  //     secretAccessKey: "",
  //     region: "us-east-1"
  //   });

  //   const params = {
  //     Bucket: "cityhotelprofile",
  //     Key: "cityhotelxs-dev.softobiz.biz" + "/" + folder + "/" + uniqueFileName,
  //     Body: file,
  //     ACL: "public-read",
  //     ContentType: contentType
  //   };

  //   bucket.upload(params, function(err, data) {
  //     if (err) {
  //       console.log("There was an error uploading your file: ", err);

  //       return false;
  //     }
  //     console.log("Successfully uploaded file.", data);

  //     return true;
  //   });

  //   //for upload progress
  //   // bucket
  //   //   .upload(params)
  //   //   .on("httpUploadProgress", function(evt) {
  //   //     console.log(evt.loaded + " of " + evt.total + " Bytes");
  //   //   })
  //   //   .send(function(err, data) {
  //   //     if (err) {
  //   //       console.log("There was an error uploading your file: ", err);
  //   //       return false;
  //   //     }
  //   //     console.log("Successfully uploaded file.", data);
  //   //     return true;
  //   //   });
  // }

  public deleteObject(key: string, imgType: string, access, folder) {
    let data = {
      ReqMethod: "deleteObject",
      FileName: key,
      ContentType: imgType,
      ACL: access,
      Folder: this.globalService.getHostname() + "/" + folder
    };
    this.masterService.getAwsSignedUrl(data).subscribe(res => {
      if (res.Status == true) {
        this.masterService
          .deleteObjectFromBucket(res.Data, imgType)
          .subscribe(res => {
            this.deleteProcessCompleted.emit({
              error: null,
              data: {
                Location: key,
                Filename: key,
                FolderName: folder
              }
            });
          });
      } else {
        this.globalService.handleErrorMessage("Error occured");
        this.deleteProcessCompleted.emit({
          error: "Error occured",
          data: null
        });
      }
    });
  }

  /*public downloadObject(key: string, name: string) {
      let data = {
          ReqMethod: 'getObject',
          FileName: key,
      }
      this.masterService.getSignedAWSResponseUrl(data).subscribe(res => {
          if (res.Status == true) {
              this
                  .downloadUrlFetched
                  .emit({
                      error: null,
                      data: {
                          url: res.Data,
                          name: name
                      }
                  });
          }
          else {
              this.globalService.handleErrorMessage('Error occured')
              this
                  .downloadUrlFetched
                  .emit({
                      error: 'Error occured',
                      data: null
                  });
          }
      });
  }*/
  /*constructor(private apiService: ApiService,private http: HttpClient) { }

  private setHeaders(): HttpHeaders {
    const headersConfig = {
      'Content-Type': 'image/jpeg',
      'Access-Control-Allow-Credentials':'/',
      'Access-Control-Allow-Origin':'/'
    }
    return new HttpHeaders(headersConfig);
  };

  uploadHotelImage(reqModel): Observable<ResultModel> {
    const route = '/hotel/uploadHotelImage';
    return this.apiService.post<ResultModel>(route, reqModel);
  }

  
  putFileToS3(data: any):Observable<HttpEvent<{}>> {
  
    const route = '/hotel/uploadImage';
    const req = new HttpRequest('POST', 'http://localhost:8181/hotel/uploadImage', data, {
      reportProgress: true
    });
 
    return this.http.request(req);
  }*/
}
